import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import AiFlow from './pages/Homepage'
import Login from './pages/Login'
import PageNotFound from './pages/PageNotFound'
import Error from './pages/Error'
import { Dna } from 'react-loader-spinner'
import { auth } from './Auth/firebaseAuth'
import { ConfigProvider } from 'antd'
import { ThemeProvider } from 'styled-components'
import { appTheme } from './style/configTheme'
import { theme } from './components/shared/theme'
import Cases from './pages/Cases'

function App() {
  const [user, setUser] = useState<any>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const storedUser = localStorage.getItem('user')
    if (storedUser) {
      setUser(JSON.parse(storedUser))
    }

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user))
      } else {
        localStorage.removeItem('user')
      }
      setUser(user)
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  if (loading) {
    return (
      <div className='loader-effects'>
        <Dna
          visible={true}
          height='80'
          width='80'
          ariaLabel='dna-loading'
          wrapperStyle={{}}
          wrapperClass='dna-wrapper'
        />
      </div>
    )
  }

  return (
    <ConfigProvider theme={appTheme}>
      <ThemeProvider theme={theme}>
        <div className='App'>
          <Router>
            <Routes>
              <Route
                path='/'
                element={<Login />}
              />
              <Route
                path='/AiFlow'
                element={user ? <AiFlow /> : <Navigate to='/' />}
              />
              <Route
                path='/Cases/:id'
                element={user ? <Cases /> : <Navigate to='/' />}
              />
              <Route
                path='/error'
                element={user ? <Error /> : <Navigate to='/' />}
              />
              <Route
                path='*'
                element={<PageNotFound />}
              />
            </Routes>
          </Router>
        </div>
      </ThemeProvider>
    </ConfigProvider>
  )
}

export default App
