import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { RadioButton } from 'primereact/radiobutton'
import { Button } from 'primereact/button'
import { FullScreen, FullScreenHandle, useFullScreenHandle } from 'react-full-screen'
import { Chip } from 'primereact/chip'
import 'primeicons/primeicons.css'
import { auth } from '../Auth/firebaseAuth'
import { InputText } from 'primereact/inputtext'
import { AI_SERVICE_URL, VariablesSelectionType } from '../utils/constants'
import { Form, Formik, Field } from 'formik'

type reportData = {
  studyData: any
  formData: any
  viewer: any
}
function Reporting(data: reportData) {
  const { studyData, formData, viewer } = data
  const [searchInput, setSearchInput] = useState('')
  const screen1 = useFullScreenHandle()
  const screen2 = useFullScreenHandle()
  const [isEditable, setIsEditable] = useState(true)

  const [userEmail, setUserEmail] = useState('')
  const [ctRatioValue, setCtRatioValue] = useState<string>(studyData?.ct_ratio || '')

  const [selectedFindings, setSelectedFindings] = useState<any>([])
  const [selectedCategory, setSelectedCategory] = useState('')

  const handleSearchInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSearchInput(event.target.value)
  }

  const filteredPathology =
    formData?.pathologies
      ?.filter((item: { pathology: string }) =>
        Boolean(item.pathology.toLowerCase().includes(searchInput.toLowerCase()))
      )
      ?.sort((a: { pathology: string }, b: { pathology: string }) => {
        const aIsNormal = a.pathology.toLowerCase() === 'normal'
        const bIsNormal = b.pathology.toLowerCase() === 'normal'

        if (aIsNormal && !bIsNormal) {
          return -1 // "Normal" comes before other values
        } else if (!aIsNormal && bIsNormal) {
          return 1 // Other values come after "Normal"
        } else {
          return 0 // Maintain the existing order
        }
      }) ?? []

  useEffect(() => {
    const user = auth.currentUser
    console.log('User:', user)
    if (user) {
      setUserEmail(user.email!)
    }
    console.log('email address from useffect', user)
  }, [])

  const handleRemoveCtRatioRemoveButtonClick = () => {
    setCtRatioValue('')
    setIsEditable(true)
  }

  const handleInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setCtRatioValue(event.target.value)
  }

  const handleCategoryChange = (category: React.SetStateAction<string>) => {
    setSelectedCategory(category)
  }

  const handleAddButtonClick = (values: any) => {
    if (values && selectedCategory != '') {
      const findingObject = {
        pathology: selectedCategory,
        info: [values],
      }

      setSelectedFindings([...selectedFindings, findingObject])
      setSelectedCategory('')
      setSearchInput('')
    }
  }

  const handleClearButtonClick = () => {
    setSelectedCategory('')
    setSelectedFindings([])
    setSearchInput('')
  }

  const handleReportButtonClick = () => {
    const formattedFindings = selectedFindings

    if (ctRatioValue) {
      const data = { pathology: 'CTR', info: [{ measure: ctRatioValue }] }
      formattedFindings.push(data)
    }

    console.log('formatted_data_format', formattedFindings)
    const apiData = {
      result: {
        action: 'report',
        study_id: studyData?.study_id,
        findings: formattedFindings,
        email: userEmail,
      },
    }

    console.log('payload_data', apiData)

    fetch(`${AI_SERVICE_URL}/user/action`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiData),
    })
      .then(response => response.json())
      .then(data => {
        console.log('API Response:', data)
        window.close()
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  const handleSkipButtonClick = () => {
    const apiData = {
      result: {
        action: 'skip',
        study_id: studyData?.study_id,
        findings: [],
        email: userEmail,
      },
    }
    console.log('study_id from chest skip', apiData)

    fetch(`${AI_SERVICE_URL}/user/action`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiData),
    })
      .then(response => response.json())
      .then(data => {
        console.log('API Response:', data)
        window.close()
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  const formatInfo = (info: any[]) => {
    return info
      .map(item =>
        Object.entries(item)
          .map(([key, value]) => key?.charAt(0)?.toUpperCase() + key?.slice(1) + ' - ' + value)
          ?.join(',')
      )
      .join(', ')
  }

  const reportChange = useCallback(
    (state: any, handle: FullScreenHandle) => {
      if (handle === screen1) {
        console.log('Screen 1 went to', state, handle)
      }
      if (handle === screen2) {
        console.log('Screen 2 went to', state, handle)
      }
    },
    [screen1, screen2]
  )

  const handleRemoveButtonClick = (index: number) => {
    const updatedSelectedFindings = [...selectedFindings]
    updatedSelectedFindings.splice(index, 1)
    setSelectedFindings(updatedSelectedFindings)
  }

  const initialValues = {}

  return (
    <div
      className='body  flex'
      style={{ background: '#F1F1F1', minHeight: '100vh' }}
    >
      <div className='left-wrapper'>
        <div className='left-container-chest'>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, action) => {
              const data = formData?.pathologies
                ?.find((data: any) => data.pathology === selectedCategory)
                ?.variables_fk?.map((a: number) =>
                  formData?.variables?.find(
                    (c: any) =>
                      a === c.id && c.selection_type === VariablesSelectionType.CHECKBOX && c.required === true
                  )
                )
                ?.find((a: any) => Boolean(a))

              if (data?.name && !(values as any)[data.name]) {
                action.setFieldError(data['name'], `Please select any one of the ${data['name']}`)
              } else {
                handleAddButtonClick(values)
                action.resetForm()
              }
            }}
          >
            {formik => (
              <Form>
                <div
                  className=' findings-wrapper '
                  style={{ height: '45vh' }}
                >
                  <div
                    className='card flex '
                    style={{ height: '100%' }}
                  >
                    <div className='flex flex-column gap-2 m-3'>
                      <label className=' findings-head'>Pathology</label>
                      <div className='card flex flex-column gap-3 '>
                        <InputText
                          type='text'
                          className='p-inputtext-sm'
                          placeholder='Search Pathology'
                          value={searchInput}
                          onChange={handleSearchInputChange}
                        />
                      </div>
                      {filteredPathology?.map((pathology: any) => (
                        <div key={pathology}>
                          <RadioButton
                            id={pathology?.pathology}
                            name='selectedPathology'
                            value={pathology?.pathology}
                            checked={selectedCategory === pathology?.pathology}
                            onChange={() => handleCategoryChange(pathology?.pathology)}
                          />
                          <label
                            htmlFor={pathology?.pathology}
                            className='p-1'
                          >
                            {pathology?.pathology}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {studyData?.mod_study === 16 && (
                  <div className='ratio-container '>
                    <div className='flex flex-column gap-2 ratio-wrapper'>
                      <label
                        htmlFor='username'
                        className='mt-3 ml-3 mr-0 mb-0 font-bold'
                      >
                        Cardio-Throcic ratio
                      </label>
                      <InputText
                        id='username'
                        className='m-2'
                        keyfilter='money'
                        placeholder='Ratio-value'
                        value={ctRatioValue}
                        disabled={!isEditable}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                )}
                <div className='zone1-container flex'>
                  <div className=' w-full side-container '>
                    {formData?.pathologies
                      ?.filter((data: { pathology: string }) => data.pathology === selectedCategory)
                      ?.map((a: { varaibles_fk: any; variables_fk: string | any[] }) => {
                        const varaibles = formData?.variables
                        return varaibles.map(
                          (c: {
                            id: any
                            mod_study_fk: number
                            options: any
                            name: string
                            required: boolean
                            selection_type: VariablesSelectionType
                          }) => {
                            const filteredOptions = a?.variables_fk?.includes(c.id) ? c.options : null

                            const name = c.name
                            return (
                              <div className='side-wrapper '>
                                <div className=' radiobutton-container'>
                                  <label className=' findings-head'>{c.name}</label>
                                  <span
                                    className='p-1'
                                    style={{ color: 'red', fontWeight: 'bold', fontSize: '0.85em' }}
                                  >
                                    {(formik as any).errors[c.name]}
                                  </span>
                                  {filteredOptions &&
                                    filteredOptions.map((item: string) => (
                                      <div key={item}>
                                        {c.selection_type === VariablesSelectionType.CHECKBOX ? (
                                          <>
                                            <Field
                                              type='checkbox'
                                              id={item}
                                              name={name}
                                              value={item}
                                              onChange={formik.handleChange}
                                            />
                                            <label
                                              htmlFor={item}
                                              className='ml-2'
                                            >
                                              {item}
                                            </label>
                                          </>
                                        ) : (
                                          <>
                                            <Field
                                              type='radio'
                                              name={name}
                                              value={item}
                                              onClick={formik.handleChange}
                                              required={c.required === true}
                                            />
                                            <label
                                              htmlFor={item}
                                              className='p-1'
                                            >
                                              {item}
                                            </label>
                                          </>
                                        )}
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )
                          }
                        )
                      })}
                  </div>
                </div>
                <div className='action-container'>
                  <Button
                    label='Clear'
                    type='button'
                    onClick={() => {
                      formik.resetForm()
                      handleClearButtonClick()
                    }}
                    className='clear-action'
                  ></Button>
                  <Button
                    label='Add'
                    type='submit'
                    className='add-action'
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className='right-container-chest'>
        <div className='top-section flex '>
          <div className='selected-findings-section'>
            <div className='findings-wrapper-right'>
              <div>
                <h3>Selected Findings</h3>
                <div>
                  {selectedFindings.map((finding: { pathology: any; info: any[] }, index: number) => (
                    <Chip
                      key={index}
                      label={`${finding.pathology} - ${formatInfo(finding.info)}`}
                      removable
                      className='chip-style'
                      onClick={() => handleRemoveButtonClick(index)}
                    />
                  ))}
                  {ctRatioValue && (
                    <Chip
                      label={`Cardio-Throcic ratio - ${ctRatioValue}`}
                      removable
                      className='chip-style'
                      onClick={handleRemoveCtRatioRemoveButtonClick}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className=' action-section'>
            <Link
              to='/Aiflow'
              className='action-wrapper'
            >
              <Button
                label='Skip'
                onClick={handleSkipButtonClick}
                className=' clear-action'
              />
              <Button
                label='Report'
                onClick={handleReportButtonClick}
                className='add-action'
              />
            </Link>
          </div>
        </div>
        <div className='dicom-container'>
          <div className='history-section'>
            <div className='history-wrapper'>
              <label className='pt-3 font-semibold'>History</label>
              <p>:&nbsp;{studyData?.history}</p>
            </div>
          </div>
          <div className=' dicom-viewer'>
            <div className='zoom-container'></div>
            <div className='dicom-wrapper'>
              <FullScreen
                handle={screen1}
                onChange={reportChange}
                className='fullscreen-container'
              >
                <div
                  className='full-screenable-node'
                  style={{ height: '100%' }}
                >
                  <div className='dicom-navbar'>
                    <i
                      className='pi pi-window-maximize'
                      // tooltip='Enter your username'
                      onClick={screen1.enter}
                      // width='100%'
                    ></i>
                    <i
                      onClick={screen1.exit}
                      className='pi pi-window-minimize'
                    ></i>
                  </div>
                  <iframe
                    title='Dicom-Viewer'
                    src={viewer}
                    width='100%'
                    height='100%'
                  ></iframe>
                </div>
              </FullScreen>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reporting
