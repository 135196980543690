export let CORE_PLATFORM_URL = 'https://e2e-staging-api.5cnetwork.com'
export let AI_SERVICE_URL = 'https://e2e-staging-api.5cnetwork.com/ai'

if (process.env.NODE_ENV === 'staging') {
  CORE_PLATFORM_URL = 'https://e2e-staging-api.5cnetwork.com'
  AI_SERVICE_URL = 'https://e2e-staging-api.5cnetwork.com/ai'
}

if (process.env.NODE_ENV === 'qa') {
  CORE_PLATFORM_URL = 'https://e2e-qa-api.5cnetwork.com'
  AI_SERVICE_URL = 'https://e2e-staging-api.5cnetwork.com/ai'
}

if (process.env.NODE_ENV === 'sandbox') {
  CORE_PLATFORM_URL = 'https://e2e-sandbox-api.5cnetwork.com'
  AI_SERVICE_URL = 'https://e2e-staging-api.5cnetwork.com/ai'
}

export enum Status {
  RECEIVED = 'RECEIVED',
  ASSIGNED = 'ASSIGNED',
  OPENED = 'OPENED',
  IN_POOL = 'IN_POOL',
  WAIT = 'WAIT',
  REPORTABLE = 'REPORTABLE',
  NON_REPORTABLE = 'NON_REPORTABLE',
  DELETED = 'DELETED',
}

export enum ModStudy {
  CHEST = 'CHEST',
  SPINE = 'SPINE',
  KNEE = 'KNEE',
}

export enum StudyCountStatuses {
  REPORTED = 'Reported',
  SKIPPED = 'Skipped',
  ACTIVE = 'Active',
}

export const ModStudyMap = {
  [ModStudy.CHEST]: [16],
  [ModStudy.KNEE]: [35],
  [ModStudy.SPINE]: [19, 20, 21, 22],
}

export enum VariablesSelectionType {
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
}

export const OSTEOCHECK_MOD_STUDY = 472
