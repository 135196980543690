import { Avatar, Card, Divider, Flex, Typography } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import Icon from './shared/Icon'

export type CountCardProps = {
  count: {
    type: string
    data: {
      [key: string]: number
    }
    total: number
    icon: string
    bgColor: string
    color: string
  }
}

const { Text } = Typography
const CountCard = (props: CountCardProps) => {
  const { count } = props
  const { color, data, total, icon, bgColor, type } = count
  return (
    <StyledCard>
      <Flex justify='space-between'>
        <Flex vertical>
          <Text style={{ fontSize: 22, color: color, fontWeight: 600 }}>{type}</Text>
          <Text
            strong
            style={{ fontSize: 22 }}
          >
            {total}
          </Text>
        </Flex>

        <Avatar
          size={70}
          src={
            <Icon
              name={icon}
              width={45}
            />
          }
          style={{ backgroundColor: bgColor }}
        />
      </Flex>
      <Divider className='my-3' />
      <Flex justify='space-between'>
        {Object.entries(data)?.map(([key, value], index: number) => (
          <Flex
            vertical
            key={index}
          >
            <Text>{key}</Text>
            <Text strong>{value}</Text>
          </Flex>
        ))}
      </Flex>
    </StyledCard>
  )
}

export default CountCard

const StyledCard = styled(Card)`
  width: 23%;
  .ant-card-body {
    padding: 1.2rem;
  }
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`
