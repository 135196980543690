export const theme: any = {
  colors: {
    appBackground: '#FCFAFA',
    primary: '#0D5192',
    primaryLight: '#e2effb',
    warning: '#FAAD14',
    lightWarning: '#FFF9DA',
    secondary: '#FFFFFF',
    secondaryDark: '#F6F7F9',
    secondaryDarker: '#F5F5F5',
    ternary: '#707070',
    ternaryLighter: '#F4F4F4',
    ternaryDark: '#2C2B2B',
    ternaryLight: '#0010D9',
    purple: '#6F4EF0',
    lightPurple: '#EBE6FF',
    accent: '#FFF500',
    timeRegular: '#0FC1C7',
    timeCritical: '#EE6958',
    timerWarning: '#FF0000',
    caseRegular: '#FFFFFF',
    caseCritical: '#FAEFF3',
    favourite: '#E6789B',
    success: '#00C69D',
    lightSuccess: '#DFFFF1',
    danger: '#C64E59',
    dull: '#C7C5C5',
    highlight1: '#E6789B',
    highlight2: '#03E2B4',
    highlight3: '#031265',
    highlight4: '#21A5FF',
    highlight5: '#FCFAB4',
    highlight6: '#F4B45D',
    highlight7: '#F8F8F8',
    highlight8: '#0066CC',
    highlight9: '#E5E8FF91',
    highlight11: '#FDD835',
    calSelectedDay: '#C3C7DB',
    progressState1: '#EE6958',
    progressState2: '#F4B45D',
    progressState3: '#FFEB00',
    progressState4: '#0FC1C7',
    transparent: 'transparent',
    black: '#000000',
    golden: '#e3a800',
    rmwcSecondary: '#FFFFFF',
    disabledMonth: '#DCE0E0',
    tagbackground1: '#E7F2FF',
    tagbackground2: '#FFF6E4',
    tagbackground3: '#E9FBFF',
    tagbackground4: '#FFF1EF',
    tagbackground5: '#fffae7',
    primaryGradient: {
      stop1: '#010529',
      stop2: '#031265',
    },
    activeGradient: {
      stop1: '#04D0A6',
      stop2: '#21A5FF',
    },
    dangerGradient: {
      stop1: '#EE6958',
      stop2: '#AB4F96',
    },
    warningGradient: {
      stop1: '#F4B45D',
      stop2: '#EE6958',
    },
    infoGradient: {
      stop1: '#7687F4',
      stop2: '#AB4F96',
    },
    timerGradient: {
      stop1: '#cffcdb',
      stop2: '#fcd2cf',
    },
  },
  boxShadows: {
    buttonBoxShadow: '#4B4B4B',
    primaryBoxShadow: '#000000',
    coloredCardShadow: '#D3D3D3',
    secondaryBoxShadow: '#F3F3F3',
  },
  breakpoints: {
    xs: '20rem', // 320px (i phone 5)
    sm: '40rem', // 640px (i phone 6,7,8 or above)
    md: '48rem', // 768px (tabs)
    lg: '64rem', // 1024px (i pad pro)
    xl: '86rem', // 1376px (laptops)
    xxl: '90rem', // 1440px (for larger screens)
  },
  fontFamily: {
    roboto: 'Roboto',
    productSans: 'ProductSans',
  },
}

export enum ZIndexes {
  z1 = 1,
  z2 = 2,
  z3 = 3,
  z5 = 5,
  z10 = 10,
  z15 = 15,
  z20 = 20,
  z25 = 25,
  z100 = 100,
  z300 = 300,
  z400 = 400,
  z500 = 500,
  z1000 = 1000,
  z1500 = 1500,
}
