import React, { useState, useRef } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import prodigi from '../Assets/prodigi-trans.png'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../Auth/firebaseAuth'
import { Toast } from 'primereact/toast'
import { useNavigate } from 'react-router-dom'
import { AI_SERVICE_URL } from '../utils/constants'

export default function Login() {
  const navigate = useNavigate()
  const toast = useRef(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const showError = () => {
    if (toast.current) {
      ;(toast.current as any).show({
        severity: 'error',
        summary: 'Error',
        detail: 'Incorrect username or password',
        life: 4000,
      })
    }
  }

  const EnableApiCall = (email: string, status: string) => {
    const apiStatus = status
    const payload = {
      result: {
        email: email,
        status: apiStatus,
      },
    }

    // console.log("login data",payload);

    return fetch(`${AI_SERVICE_URL}/user/on-off`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
  }

  // const signIn = (e) => {
  //   e.preventDefault();
  //   signInWithEmailAndPassword(auth, email, password)
  //     .then((userCredential) => {
  //       navigate('/AiFlow')
  //       // EnableApiCall();
  //       console.log(" Login Successful",userCredential);
  //     })
  //     .catch((error) => {
  //       // showError
  //       showError();
  //       console.log(error);
  //     });
  // };

  const signIn = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        EnableApiCall(email, 'ENABLE')
        localStorage.setItem('user', JSON.stringify(userCredential.user))
        navigate('/AiFlow')
        console.log('Login Successful', userCredential)
      })
      .catch(error => {
        showError()
        console.log(error)
      })
  }

  return (
    <form onSubmit={signIn}>
      <div className='card h-screen surface-300'>
        <form onSubmit={signIn}></form>
        <div className='flex flex-column justify-content-center h-screen  md:flex-row'>
          <div className='w-full md:w-3 flex flex-column align-items-center justify-content-center gap-3 py-5 login-container'>
            <img
              src={prodigi}
              className='prodigi-img'
            ></img>
            {/* <label className='breaker'>AI-Flow</label> */}
            <div className='flex flex-wrap justify-content-center align-items-center gap-2'>
              <label className='w-4rem'>
                <i
                  className='pi pi-user'
                  style={{ fontSize: '2rem' }}
                ></i>
              </label>
              <InputText
                id='username'
                type='text'
                placeholder='Email'
                value={email}
                onChange={e => setEmail(e.target.value)}
                className='w-12rem'
                required
              />
            </div>
            <div className='flex flex-wrap justify-content-center align-items-center gap-2'>
              <label className='w-4rem'>
                <img
                  width='35'
                  height='35'
                  src='https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/64/external-password-cycber-security-system-inipagistudio-mixed-inipagistudio.png'
                  alt='external-password-cycber-security-system-inipagistudio-mixed-inipagistudio'
                />
              </label>
              <InputText
                id='password'
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder='password'
                className='w-12rem'
                required
              />
            </div>

            <Button
              label='Login'
              ref={Toast as any}
              icon='pi pi-user'
              className='w-10rem mx-auto mt-5'
            ></Button>
            <Toast ref={toast} />
          </div>
        </div>
      </div>
    </form>
  )
}
