import React, { useState, useEffect, useRef, useMemo } from 'react'
import '../App.css'
import '/node_modules/primeflex/primeflex.css'
import { useNavigate } from 'react-router-dom'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Tag } from 'primereact/tag'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import { Badge } from 'primereact/badge'
import forwards from '../Assets/forward.png'
import axios from 'axios'
import prodigi from '../Assets/prodigi-trans.png'
import { auth } from '../Auth/firebaseAuth'
import { AI_SERVICE_URL, ModStudy, ModStudyMap, Status, StudyCountStatuses } from '../utils/constants'
import { Toast } from 'primereact/toast'
import { Avatar } from 'antd'
import { theme } from '../components/shared/theme'
import { Flex } from 'antd'
import CountCard from '../components/CountCard'
import Icon from '../components/shared/Icon'

type CaseCount = {
  status: Status
  count: number
}

type ModStudyCount = {
  [mod_study: string | number]: CaseCount[]
}

export default function Homepage() {
  const [filters, setFilters] = useState<any>({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    patient_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }],
    },
    'country.name': {
      operator: FilterOperator.AND,
      constraints: [{ value: '', matchMode: FilterMatchMode.STARTS_WITH }],
    },
    date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: '', matchMode: FilterMatchMode.EQUALS }],
    },
  })
  const [loading, setLoading] = useState(false)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [statuses] = useState<string[]>(['NON_REPORTABLE', 'REPORTABLE', 'ASSIGNED', 'IN_POOL'])
  const [data, setdata] = useState<any>([])
  const [caseCountData, setCaseCountData] = useState<ModStudyCount | undefined>()
  const [selectedDate, setSelectedDate] = useState<any>(new Date())
  const [userEmail, setUserEmail] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [count, setCount] = useState<number>(0)
  const navigate = useNavigate()
  const toast = useRef(null)

  const firstLetter = userEmail ? userEmail.charAt(0).toUpperCase() : ''
  const avatarLabel = userEmail ? firstLetter : 'U'

  const getSeverity = (status: string) => {
    let mappedStatus = ''
    let color = ''

    switch (status) {
      case 'REPORTABLE':
        mappedStatus = 'Reported'
        color = 'success'
        break
      case 'NON_REPORTABLE':
        mappedStatus = 'Skipped'
        color = 'danger'
        break
      case 'WAIT':
        mappedStatus = 'Waiting'
        color = 'info'
        break
      case 'ASSIGNED':
        mappedStatus = 'Assigned'
        color = 'info'
        break
      case 'IN_POOL':
        mappedStatus = 'In Pool'
        color = 'success'
        break
      default:
        mappedStatus = status
        color = 'default'
        break
    }

    return { mappedStatus, color }
  }

  useEffect(() => {
    if (userEmail !== null) {
      apiCall(selectedDate)
    }
    const interval = setInterval(() => {
      apiCall(selectedDate)
      console.log('calltiming')
    }, 5000)

    return () => clearInterval(interval)
  }, [selectedDate, userEmail])

  const DisableApiCall = () => {
    const apistatus = 'DISABLE'
    const payload = {
      result: {
        email: userEmail,
        status: apistatus,
      },
    }
    console.log('payload logout data', payload)
    const apistat = fetch(`${AI_SERVICE_URL}/user/on-off`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    console.log('logout status', apistat)
    return apistat
  }

  const apiCall = async (selectedDate: any) => {
    const formattedDate = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(selectedDate.getDate()).padStart(2, '0')}`
    const apiURL = `${AI_SERVICE_URL}/user/cases?date=${formattedDate}&user=${userEmail}`

    try {
      const response = await axios.get(apiURL)
      console.log('Fetch case api', response)
      const sortedData = response.data.data.cases.sort(
        (a: { status: string; created_at: any }, b: { status: string; created_at: any }) => {
          if (a.status === 'ASSIGNED' && b.status === 'ASSIGNED') {
            return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          } else if (a.status === 'ASSIGNED') {
            return -1
          } else if (b.status === 'ASSIGNED') {
            return 1
          } else if (
            (a.status === 'REPORTABLE' || a.status === 'NON_REPORTABLE') &&
            (b.status === 'REPORTABLE' || b.status === 'NON_REPORTABLE')
          ) {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          } else {
            return 0
          }
        }
      )
      setdata(sortedData)
      setCaseCountData(response.data.data.count)
      console.log('sorted_data', sortedData)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    console.log('searched_data', data)
  }, [data])

  useEffect(() => {
    const user = auth.currentUser
    if (user) {
      setUserEmail(user.email!)
    } else {
      setUserEmail('')
    }
    console.log('email address from useffect', user)
  }, [])

  const clearFilter = () => {
    initFilters()
  }

  const onGlobalFilterChange = (e: { target: { value: string } }) => {
    const value = e.target.value.toLowerCase()
    // eslint-disable-next-line
    let _filters = { ...filters }

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      patient_name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      'country.name': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      status: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    })
    setGlobalFilterValue('')
  }

  const getNewCase = () => {
    setIsLoading(true)
    fetch(`${AI_SERVICE_URL}/user/get-next-case?email=${userEmail}`, {
      method: 'GET',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        return response.json()
      })
      .then(data => {
        if (!data.id) {
          // Show toast for missing id
          ;(toast.current as any).show({
            severity: 'warn',
            summary: 'Warning',
            detail: 'No case found!',
            life: 4000,
          })
        } else {
          console.log(data)
          window.open(`/Cases/${data.id}`, '_blank')
        }
        setIsLoading(false)
      })
      .catch(error => {
        // Show toast for general error
        ;(toast.current as any).show({
          severity: 'error',
          summary: 'Error',
          detail: `Failed to Get new case: ${error.message}`,
          life: 4000,
        })
        setIsLoading(false)
      })
  }

  const getPoolCount = () => {
    setIsLoading(true)
    fetch(`${AI_SERVICE_URL}/user/get-pool-count?user_email=${userEmail}`, {
      method: 'GET',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        } else {
          // Parse response body as JSON
          return response.json()
        }
      })
      .then(data => {
        if (data) {
          setCount(data.pool_count)
        } else {
          console.log('failed')
        }
        setIsLoading(false)
      })
      .catch(error => {
        // Show toast for general error
        ;(toast.current as any).show({
          severity: 'error',
          summary: 'Error',
          detail: `Failed to Get count: ${error.message}`,
          life: 4000,
        })
        setIsLoading(false)
      })
  }

  const getCountData = useMemo(() => {
    const modStudyList = [ModStudy.SPINE, ModStudy.CHEST, ModStudy.KNEE]
    const result: { [key: string]: { [key: string]: number } } = {
      TOTAL: { [StudyCountStatuses.REPORTED]: 0, [StudyCountStatuses.SKIPPED]: 0, [StudyCountStatuses.ACTIVE]: 0 },
      [ModStudy.SPINE]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.CHEST]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.KNEE]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
    }
    for (const study of modStudyList) {
      for (const modStudyId of ModStudyMap[study]) {
        const data = caseCountData?.[modStudyId]
        if (data) {
          for (const val of data) {
            switch (val.status) {
              case 'REPORTABLE':
                result[study][StudyCountStatuses.REPORTED] += val.count
                result.TOTAL[StudyCountStatuses.REPORTED] += val.count
                break
              case 'NON_REPORTABLE':
                result[study][StudyCountStatuses.SKIPPED] += val.count
                result.TOTAL[StudyCountStatuses.SKIPPED] += val.count
                break
              case 'ASSIGNED':
              case 'OPENED':
                result[study][StudyCountStatuses.ACTIVE] += val.count
                result.TOTAL[StudyCountStatuses.ACTIVE] += val.count
                break
            }
          }
        }
      }
    }

    const data = [
      {
        type: 'Overall Case',
        study: 'TOTAL',
        icon: 'overall-case',
        bgColor: theme.colors.primaryLight,
        color: theme.colors.primary,
      },
      {
        type: 'Chest Case',
        study: ModStudy.CHEST,
        icon: 'chest',
        bgColor: theme.colors.lightWarning,
        color: theme.colors.warning,
      },
      {
        type: 'Knee Case',
        study: ModStudy.KNEE,
        icon: 'knee',
        bgColor: theme.colors.lightPurple,
        color: theme.colors.purple,
      },
      {
        type: 'Spine Case',
        study: ModStudy.SPINE,
        icon: 'spine',
        bgColor: theme.colors.lightSuccess,
        color: theme.colors.success,
      },
    ]

    return data.map(({ type, study, icon, bgColor, color }) => ({
      type,
      data: result[study],
      total: Object.values(result[study]).reduce((acc: number, curr: any) => acc + curr, 0),
      icon,
      bgColor,
      color,
    }))
  }, [caseCountData])

  useEffect(() => {
    if (userEmail) {
      getPoolCount()
    }
  }, [userEmail])

  const renderHeader = () => {
    return (
      <div className='flex justify-content-between'>
        <Button
          type='button'
          icon='pi pi-filter-slash'
          label='Clear'
          outlined
          onClick={clearFilter}
        />
        <div className='flex-horizontal'>
          <Button
            type='button'
            icon='pi pi-bolt'
            label={`Get New Case (${count})`}
            outlined
            onClick={getNewCase}
            severity='warning'
            loading={isLoading}
            text
            raised
          />
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder='Name Search'
            />
          </span>
        </div>
        <Toast ref={toast} />
      </div>
    )
  }

  // const filterApplyTemplate = (options) => {
  //     return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} severity="success"></Button>;
  // };

  const dateFilterTemplate = () => {
    return (
      <Calendar
        value={selectedDate}
        onChange={e => setSelectedDate(e.value)}
        dateFormat='mm/dd/yy'
        placeholder='mm/dd/yyyy'
        mask='99/99/9999'
        showIcon
      />
    )
  }

  const balanceBodyTemplate = (rowData: { order_id: string; paediatric: boolean; critical: boolean }) => {
    return (
      <Flex style={{ alignItems: 'center' }}>
        <Flex
          vertical
          style={{ position: 'fixed', left: 20 }}
        >
          {/* {rowData.paediatric && <Icon name='critical' />} */}
          {rowData.critical && <Icon name='critical' />}
        </Flex>
        {rowData.order_id}
      </Flex>
    )
  }

  const studyID = (rowData: { study_id: number }) => {
    return (
      <a
        href={`https://admin.5cnetwork.com/cases/${rowData.study_id}?tab=Images`}
        target='_blank'
        className='case-hyper-link'
      >
        {rowData.study_id}
      </a>
    )
  }

  const patient_name = (rowData: { patient_name: string }) => {
    return rowData.patient_name
  }

  const Study_Name = (rowData: { report_header: string }) => {
    return rowData.report_header
  }
  const mod_study = (rowData: { mod_study: any }) => {
    return rowData.mod_study
  }

  const dateBodyTemplate = (rowData: { created_at: any }) => {
    const formattedDate = new Date(rowData.created_at).toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })

    const datePart = formattedDate.slice(0, -8)
    const timePart = formattedDate.slice(-8)

    return (
      <div>
        <div className='date-part'>{datePart} </div>
        <div className='time-part'>{timePart}</div>
      </div>
    )
  }

  const statusBodyTemplate = (rowData: { status: string }) => {
    const { mappedStatus, color } = getSeverity(rowData.status)
    return (
      <Badge
        value={mappedStatus}
        severity={color as any}
      />
    )
  }

  const statusFilterTemplate = () => {
    const statusLabels: { [key: string]: string } = {
      REPORTABLE: 'Reported',
      NON_REPORTABLE: 'Skipped',
      WAIT: 'Waiting',
      ASSIGNED: 'Assigned',
      IN_POOL: 'In Pool',
    }
    const onStatusFilterChange = (selectedStatus: string) => {
      // eslint-disable-next-line
      let _filters = { ...filters }
      _filters['status'].constraints[0].value = selectedStatus
      setFilters(_filters)
    }

    return (
      <Dropdown
        value={filters.status.constraints[0].value}
        options={statuses.map(status => ({
          label: (
            <Tag
              value={statusLabels[status]}
              severity={getSeverity(status).color as any}
            />
          ),
          value: status,
        }))}
        onChange={e => onStatusFilterChange(e.value)}
        placeholder='Select One'
        className='p-column-filter'
        showClear
      />
    )
  }

  const clearLocalStorage = () => {
    localStorage.removeItem('user')
  }

  const handleButtonClick = () => {
    DisableApiCall()
    clearLocalStorage()
    navigate('/')
  }

  const actionBodyTemplate = (rowData: { study_id: number }) => {
    const { study_id } = rowData

    const handleRedirect = () => {
      window.open(`/Cases/${study_id}`, '_blank')
    }

    return (
      <div
        onClick={handleRedirect}
        className='cursor-pointer'
      >
        <img
          width='38'
          height='38'
          src={forwards}
          alt='report'
          className='forward'
        />
      </div>
    )
  }

  const header = renderHeader()

  return (
    <div>
      <div className='navbar'>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <img
            src={prodigi}
            className='homepage-navbar'
          ></img>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar size='large'>{avatarLabel}</Avatar>
            <Button
              label='Logout'
              severity='secondary'
              text
              onClick={handleButtonClick}
            >
              <i className='pi pi-sign-out  ml-2'></i>
            </Button>
          </div>
        </div>
      </div>

      <Flex
        justify='space-between'
        className='mt-8 mx-5 p-0'
      >
        {getCountData.map((items, index) => (
          <CountCard
            count={items}
            key={index}
          />
        ))}
      </Flex>
      <div className='table-container'>
        <DataTable
          value={data}
          paginator
          showGridlines
          rows={10}
          loading={loading}
          dataKey='id'
          filters={filters}
          globalFilterFields={['patient_name', 'country.name', 'representative.name', 'balance', 'status']}
          emptyMessage='No data found.'
          className=' table-head shadow-2 '
          header={header}
        >
          <Column
            header='Order ID'
            dataType='numeric'
            style={{ minWidth: '10rem' }}
            body={balanceBodyTemplate}
          />
          <Column
            header='Study ID'
            dataType='numeric'
            style={{ minWidth: '10rem' }}
            body={studyID}
          />
          <Column
            field='patient_name'
            header='Patient Name'
            filterField='patient_name'
            style={{ minWidth: '12rem' }}
            body={patient_name}
          />
          <Column
            header='Study Name'
            style={{ minWidth: '12rem' }}
            body={Study_Name}
          />
          <Column
            header='Mod_study'
            style={{ minWidth: '12rem', display: 'none' }}
            body={mod_study}
            className='modstudy'
          />
          <Column
            header='Created at'
            filterField='date'
            dataType='date'
            style={{ minWidth: '10rem' }}
            body={dateBodyTemplate}
            filter
            filterElement={dateFilterTemplate}
          />
          <Column
            field='status'
            header='Status'
            filterMenuStyle={{ width: '14rem' }}
            style={{ minWidth: '12rem' }}
            body={statusBodyTemplate}
            filter
            filterElement={statusFilterTemplate}
          />
          <Column
            header='View'
            style={{ minWidth: '12rem' }}
            body={actionBodyTemplate}
          />
        </DataTable>
      </div>
    </div>
  )
}
