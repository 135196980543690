import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { AI_SERVICE_URL, CORE_PLATFORM_URL, OSTEOCHECK_MOD_STUDY } from '../utils/constants'
import Reporting from './Reporting'
import Osteocheck from './Osteocheck'
import { Dna } from 'react-loader-spinner'
import styled from 'styled-components'

export interface studyData {
  created_at: Date
  ct_ratio: any
  history: string
  mod_study: number
  order_id: string
  patient_name: string
  report_header: string
  status: string
  study_id: number
  study_iuid: string
}

export interface formData {
  pathologies: any
  variables: any
}

const Cases = () => {
  const [osteocheck, setOsteocheck] = useState(false)
  const [loading, setLoading] = useState(true)
  const [studyData, setStudyData] = useState<studyData>()
  const [formData, setFormData] = useState<formData>()
  const [viewer, setviewer] = useState<any>([])
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    dicomcall()
  }, [studyData])

  useEffect(() => {
    fetch(`${AI_SERVICE_URL}/study/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data?.error) {
          navigate('/error')
        } else {
          setStudyData(data?.data?.[0] ?? {})
          if (data?.data?.[0]?.mod_study === OSTEOCHECK_MOD_STUDY) {
            setOsteocheck(true)
          } else {
            fetch(`${AI_SERVICE_URL}/report/templates?mod_study_id=${data?.data?.[0]?.mod_study}&user_email=dsf`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .then(response1 => {
                return response1.json()
              })
              .then(data1 => {
                console.log(data1)
                if (data1?.error) {
                  navigate('/error')
                } else {
                  setFormData(data1 ?? {})
                }
              })
              .catch(error => {
                navigate('/error')
                console.error('Error:', error)
              })
          }
        }
        setLoading(false)
      })
      .catch(error => {
        navigate('/error')
        console.error('Error:', error)
      })
  }, [])

  const dicomcall = async () => {
    try {
      const iuid = studyData?.study_iuid
      const response2 = await fetch(`${CORE_PLATFORM_URL}/dicom/viewer-token?study_iuid=${iuid}`)
      const token = await response2.text()
      console.log(token)
      const dicomviewer = `https://dcm.5cnetwork.com/?token=${token}`
      console.log(dicomviewer)
      setviewer(dicomviewer)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  if (loading) {
    return (
      <LoaderWrapper>
        <Dna
          visible={true}
          height='150'
          width='150'
          ariaLabel='dna-loading'
          wrapperStyle={{}}
          wrapperClass='dna-wrapper'
        />
      </LoaderWrapper>
    )
  }

  return (
    <div>
      {osteocheck ? (
        <Osteocheck
          studyData={studyData}
          viewer={viewer}
        />
      ) : (
        <Reporting
          studyData={studyData!}
          formData={formData!}
          viewer={viewer}
        />
      )}
    </div>
  )
}

export default Cases

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
